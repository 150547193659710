<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="9"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="9" ></LeftNav>
                <div class="flex-grow-1">
                    <div class="ml20 pt30 plr30 personal basic_info">
                        <div class="fs20 fwb pb20">{{$t('changepassword')}} </div>
                        <div class="flex-box flex-col-start">
                            <div>
                                <div class="fs16 col5 pb5">{{$t('oldpassword')}}</div>
                                <el-input class="input mb30" placeholder="enter old password" type="password" v-model="oldpassword"></el-input>
                                <div class="fs16 col5 pb5">{{$t('newpassword')}}</div>
                                <el-input class="input mb30" placeholder="enter new password" type="password" v-model="password"></el-input>
                                <div class="fs16 col5 pb5">{{$t('confirmpassword')}}</div>
                                <el-input class="input mb30" placeholder="confirm new password" type="password" v-model="password2"></el-input>
                            </div>

                        </div>

                        <div class="flex-box flex-col-start">
                            <a href="javascript:;" class="btn fs16 colf confirm" @click="saveconfirm">{{$t('confirm')}}</a>
                        </div>
                    </div>

                    <div class="ml20 pt30 plr30 personal basic_info mt20">
                        <div class="fs20 fwb pb20" sty>{{$t('transactionpasswordsettings')}}</div>
                        <div class="flex-box flex-col-start">
                            <div>
                                <div class="fs16 col5 pb5">{{$t('oldtranscation')}}</div>
                                <!-- <el-input class="input mb30" placeholder="enter new password" type="password" v-model="password"></el-input> -->
                                <el-input class="input mb30" placeholder="enter your transaction password"  type="password"  v-model="oldpwd"></el-input>
                                <div class="fs16 col5 pb5">{{ $t('newtranscation') }}</div>
                                <!-- <el-input class="input mb30" placeholder="enter new password" type="password" v-model="password"></el-input> -->
                                <el-input class="input mb30" placeholder="enter new transaction password"  type="password"  v-model="pwd"></el-input>
                                <div class="fs16 col5 pb5">{{ $t('confirmtranscation') }}</div>
                                <!-- <el-input class="input mb30" placeholder="enter new password" type="password" v-model="password"></el-input> -->
                                <el-input class="input mb30" placeholder="confirm new transaction password"  type="password"  v-model="pwd2"></el-input>
                            </div>

                        </div>

                        <div class="flex-box flex-col-start">
                            <a href="javascript:;" class="btn fs16 colf confirm" @click="saveconfirm2">{{$t('confirm')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";

export default {
    data() {
        return {
            oldpassword:'',
            password:'',
            password2:'',
            oldpwd:'',
            pwd:'',
            pwd2:'',
            user:{}
        };
    },
    created(){
        this.getuser()
    },
    methods: {
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){
					this.user = ret.data.userinfo;
				}
			})
        },
        saveconfirm(){
            if (!this.oldpassword) {
                return this.$message({
                    message: '请填写当前密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.password) {
                return this.$message({
                    message: '请填写密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.password2) {
                return this.$message({
                    message: '请填写确认密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写确认密码')
            }
            if (this.password2 != this.password) {
                return this.$message({
                    message: '两次密码不同',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('两次密码不同')

            }
            var data={newpassword:this.password,oldpassword:this.oldpassword}
            this.$api.changepassword(data).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    // localStorage.removeItem("sma_token");
                    // cookie.remove("sma_token");
                    // cookie.remove("sma_userinfo");
                    window.location.reload(true);
                }else{
                    // this.$message.error(ret.msg)
                    this.$message({
                    message: ret.msg,
                    type: 'error',
                    offset: window.screen.height / 2
                })
                }
            })
        },
        saveconfirm2(){
            if (!this.oldpwd) {
                return this.$message({
                    message: '请填写当前交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.pwd) {
                return this.$message({
                    message: '请填写新交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.pwd2) {
                return this.$message({
                    message: '请填写确认交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写确认密码')
            }
            if (this.pwd != this.pwd2) {
                return this.$message({
                    message: '两次密码不同',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('两次密码不同')

            }
            var data={pwd:this.pwd,oldpassword:this.oldpwd}
            this.$api.apiPostPassword(data).then(ret=>{
                if(ret.code==1){
                    this.pwd=''
                    this.oldpwd=""
                    this.pwd2=""
                    // this.$message.success(ret.msg);
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                }else{
                    // this.$message.error(ret.msg);
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        tpwd(){
            if(!this.pwd){
                // return this.$message.error('密码不可为空')
                return this.$message({
                    message: '密码不可为空',
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
            this.$api.apiPostPassword({pwd:this.pwd}).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg);
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    this.pwd=''
                }else{
                    // this.$message.error(ret.msg);
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        }
    }
};
</script>
